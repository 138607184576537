import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import config from "../../data/SiteConfig";
import Layout from "../layout";

const AlbumCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlbumIframe = styled.iframe`
  border: 0;
  width: 100%;
  margin: 3rem;
  min-height: calc(100vh - 114px - 6rem);
  border-radius: 20px;
  border: 1px solid grey;
  box-shadow: 0px 0px 5px black;
`;

function MusicPage() {
  return (
    <Layout location="music" title="Music">
      <div className="about-container">
        <Helmet title={`About | ${config.siteTitle}`} />
        <Row
          style={{ minHeight: "calc(100vh - 104px)" }}
        >
          <AlbumCol md={6} lg={4}>
            <AlbumIframe
              title="NSSF"
              src="https://bandcamp.com/EmbeddedPlayer/album=2502642399/size=large/bgcol=333333/linkcol=0687f5/transparent=true/"
              seamless
            >
              <a href="https://tangentcouncil.bandcamp.com/album/north-star-south-flower">
                North Star South Flower by Tangent Council
              </a>
            </AlbumIframe>
          </AlbumCol>
          <AlbumCol md={6} lg={4}>
            <AlbumIframe
              title="Chrome"
              src="https://bandcamp.com/EmbeddedPlayer/album=1229072463/size=large/bgcol=333333/linkcol=0687f5/transparent=true/"
              seamless
            >
              <a href="https://tangentcouncil.bandcamp.com/album/crome">
                Crome by Tangent Council
              </a>
            </AlbumIframe>
          </AlbumCol>
          <AlbumCol md={6} lg={4}>
            <AlbumIframe
              src="https://bandcamp.com/EmbeddedPlayer/album=2780922782/size=large/bgcol=333333/linkcol=0687f5/transparent=true/"
              seamless
            >
              <a href="https://tangentcouncil.bandcamp.com/album/oars">
                oars by Tangent Council
              </a>
            </AlbumIframe>
          </AlbumCol>
        </Row>
      </div>
    </Layout>
  );
}

export default MusicPage;
